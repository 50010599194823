<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="rows">
        <div
          class="
            layout-column
            cols
            row-1 row
            layout-align-center-center
            col-padding
          "
        >
          <section-title
            :partNo="part.id"
            :partTitle="part.title"
            data-depth="2.5"
            class="parallax-item parallax-relative"
            :show="showTitle"
          ></section-title>
        </div>
        <div class="layout-row cols row-2 row">
          <div
            class="col-1 flex col-padding layout-column layout-align-start-end"
          >
            <info-modal-box
              id="intro-box"
              :show="showInfoBoxes"
              class="parallax-item parallax-relative section-infobox"
              data-depth="3.8"
              :data="data.boxes.info_1"
            >
            </info-modal-box>
          </div>
          <div
            class="col-2 col-padding layout-column layout-align-center-center"
          >
            <quote
              :data="data.boxes.quote"
              :fontSize="2"
              :maxWidth="'18em'"
              :overlayOpacity="0"
              :show="showQuote"
              data-depth="4.8"
              class="parallax-item parallax-relative"
              :timescale="0.7"
              :splitLines="true"
            ></quote>
          </div>
        </div>
      </div>
    </div>

    <background-image-full
      :image="data.images.background"
      :show="showBackground"
      :imageCss="{ objectPosition: 'center center' }"
      :mask="true"
      :maskOpacity="0"
    ></background-image-full>
    <div class="container absolute fluid">
      <decal
        id="decal-aloe"
        class="absolute"
        :show="showDecal"
        :image="data.images.aloe"
        :customTimeline="timelines.timelineAloe"
      ></decal>
      <decal
        id="decal-ansellia"
        class="absolute"
        :show="showDecal"
        :image="data.images.ansellia"
        :customTimeline="timelines.timelineAnsellia"
      ></decal>
    </div>
    <div class="container fixed fluid">
      <decal
        id="decal-leopard"
        class="absolute"
        :show="showMisc"
        :image="data.images.leopard"
        :customTimeline="timelines.timelineLeopard"
      ></decal>
    </div>

    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import BackgroundImageFull from "@/components/ui/BackgroundImageFull.vue";
import SectionTitle from "@/components/layout/SectionTitle.vue";
import Decal from "@/components/ui/Decal.vue";
import Quote from "@/components/ui/Quote.vue";
import InfoModalBox from "@/components/ui/InfoModalBox.vue";

import ModalMain from "@/modals/ModalMain.vue";

export default {
  name: "Chapter-2-1",
  mixins: [chapterPart],
  components: {
    BackgroundImageFull,
    SectionTitle,
    Decal,
    Quote,
    InfoModalBox,
    ModalMain,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 0],
        ["showBackground", 700],
        ["showTitle", 600],
        ["showQuote", 400],
        ["showInfoBoxes", 200],
        ["showDecal", 0],
        ["showMisc", 1400],
      ],
      timelines: {
        timelineLeopard: {
          duration: 1.2,
          animations: {
            ease: Power1.easeInOut,
            autoAlpha: 0,
            x: "-=30%",
            scale: "1",
          },
        },
        timelineAloe: {
          duration: 1.2,
          animations: {
            ease: Power1.easeInOut,
            autoAlpha: 0,
            scale: "0.8",
            y: "+=30%",
            x: "+=30%",
          },
        },
        timelineAnsellia: {
          duration: 1.2,
          animations: {
            ease: Power1.easeInOut,
            autoAlpha: 0,
            scale: "0.8",
            y: "-=30%",
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.col-1 {
  max-width: 38%;
}
.col-2 {
}
.info-box-wrap {
  margin-top: 10.5%;
}
.section-title {
  max-width: none;
  margin-left: 15%;
}
.section-quote {
  margin-top: 3.6em;
  margin-bottom: 17rem;
  left: auto !important;
  top: auto !important;
}
#decal-leopard {
  height: 107vh;
  left: -2%;
  top: auto;
  bottom: -9vh;
  margin: 0;
}
/deep/ #decal-leopard img {
  max-height: 100%;
}
#decal-ansellia {
  left: 16%;
  top: -1.5%;
  max-width: 26%;
}

#decal-aloe {
  right: -0.5%;
  bottom: -1.5%;
  max-width: 45vw;
  min-width: 42vh;
  z-index: 0;
}

/deep/ #decal-aloe img {
}

/deep/ .info-box {
  background: rgba(255, 255, 255, 0.8);
}

.portrait #decal-leopard {
  left: -9rem;
}
/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1440px) {
  .col-1 {
    padding-right: 0;
  }
  .section-title {
    align-self: flex-end;
    margin-right: 15%;
  }
  #decal-ansellia {
    left: 22%;
    max-width: 30%;
  }
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1200px) {
  .section-quote {
    font-size: 0.9rem;
    margin-bottom: 15rem;
  }
  #decal-aloe {
    bottom: -1%;
  }
  #decal-leopard {
    bottom: -12vh;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 1023px) {
  .section-quote {
    font-size: 0.7rem;
    margin-bottom: 15rem;
  }
}

@media screen and (max-width: 991px) {
  .col-1 {
    max-width: 41%;
  }
}

@media screen and (max-width: 880px) {
  .col-1 {
    max-width: 50%;
  }
}

@media screen and (max-width: 830px) {
  .col-1 {
    padding: var(--col-padding);
    order: 2;
    margin-bottom: 6rem;
  }
  .col-2 {
    order: 1;
  }
  .row-2 {
    flex-direction: column;
  }
  .row-2 > * {
    width: 100%;
    max-width: 100%;
    align-items: center;
  }
  .section-quote {
    width: auto;
  }
  .section-quote {
    margin: 3.6em 0 3em 30%;
  }
  .section-title {
    margin-right: 10%;
  }
  #decal-ansellia {
    left: 24%;
  }
  #decal-aloe {
    min-width: 400px;
  }
  /deep/ .section-quote .quote-quote {
    width: 100% !important;
    max-width: 25em;
  }
}

@media screen and (max-width: 767px) {
  #decal-ansellia {
    left: calc(var(--button-rem) * 15);
  }
  .landscape #decal-ansellia {
    left: 12%;
    max-width: 40vw;
  }
  .portrait #decal-leopard {
    left: -12vh;
  }
}

@media screen and (max-width: 639px) {
  #decal-aloe {
    min-width: 300px;
  }
  .section-quote {
    font-size: 9px;
  }
}

@media screen and (max-width: 600px) {
  .section-quote {
    font-size: 7px;
    margin-left: 23%;
  }
  .portrait .col-1 {
    margin-bottom: 12rem;
  }
  .portrait #decal-aloe {
    min-width: 260px;
  }
}

@media screen and (max-width: 520px) {
  .portrait #decal-ansellia {
    display: none;
  }
}

@media screen and (max-width: 479px) {
}

@media screen and (max-width: 420px) {
  #decal-ansellia {
    display: none;
  }
  .section-quote {
    font-size: 6.8px;
    margin-left: 25%;
  }
}
</style>
